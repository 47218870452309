import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import LocalOffer from "@material-ui/icons/LocalOffer"
import { TagsContainer, TagsStyled } from "../components/styles/styles"
import { getSingleTagsFromArray } from "../utils/utils"
import { SectionTitle, BorderedGrid } from "../components/styles/styles"

const _ = require("lodash")

const TagsPage = ({ data }) => {
  const tags = getSingleTagsFromArray(data.allMarkdownRemark.group)
  return (
    <Layout>
      <TagsContainer>
        <SectionTitle align="center">Browse by Category</SectionTitle>
        <ul>
          {tags.map(tag => (
            <li key={tag}>
              <Link to={`/tags/${_.kebabCase(tag)}/`}>
                <span>
                  <LocalOffer />
                  {tag}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </TagsContainer>
    </Layout>
  )
}
export default TagsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
